<template>
  <div>
    <Header />
    <div class="logo">
      <div class="logopic">
        <div class="logopic-left">
          <img @click="home" src="../../assets/login/logo.gif" alt="" />
          <div class="zhuce">欢迎注册</div>
        </div>
        <div class="logopic-right">
          <div class="logopic-right_L">已有账号？</div>
          <router-link
            :to="{
              name: 'Login',
            }"
            tag="div"
            class="logopic-right_R"
            >请登录></router-link
          >
        </div>
      </div>
    </div>
    <div class="conter">
      <div class="buzhou">
        <svg class="logo-search-progress">
          <image
            x="0"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-02.png"
          ></image>
          <image
            x="129"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-02.png"
          ></image>
          <image
            x="259"
            y="30"
            width="130"
            height="6"
            xlink:href="../../assets/settlement/progress-02.png"
          ></image>
          <image
            x="50"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-02.png"
          ></image>
          <image
            x="180"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-02.png"
          ></image>
          <image
            x="310"
            y="8"
            width="27"
            height="38"
            xlink:href="../../assets/settlement/progress-icon-02.png"
          ></image>
          <text x="60" y="38" style="fill:#fff;">1</text>
          <text x="190" y="38" style="fill:#fff;">2</text>
          <text x="320" y="38" style="fill:#fff;">3</text>
          <text x="25" y="58" style="fill:#ff902a;">验证手机号</text>
          <text x="150" y="58" style="fill:#ff902a;">填写账号信息</text>
          <text x="295" y="58" style="fill:#ff902a;">注册成功</text>
        </svg>
      </div>
      <div class="success">
        <img src="../../assets/order/succeed.png" alt="" />
        <div class="zhuce">注册成功</div>
      </div>
      <router-link tag="div" :to="{ name: 'Login' }" class="denglu"
        >登录</router-link
      >
    </div>
    <Login_bottom />
    <el-button :plain="true" v-show="false"></el-button>
  </div>
</template>
<script>
import Header from '@/components/header.vue';
import Login_bottom from '@/components/login/login_bottom.vue';
export default {
  components: {
    Login_bottom,
    Header,
  },
  data() {
    return {};
  },
  methods: {
    //手机验证发送验证码
    home() {
      this.$router.push({
        name: 'Home',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  width: 100%;
  height: 110px;
  border-bottom: 3px solid #f5f5f5;
  .logopic {
    width: 1234px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      img {
        width: 158px;
        height: 44px;
        margin-left: 60px;
        cursor: pointer;
      }
      .zhuce {
        font-size: 28px;
        color: #245af2;
        font-weight: 600;
        margin-left: 27px;
      }
    }
    &-right {
      height: 100px;
      font-size: 15px;
      display: flex;
      align-items: flex-end;
      &_L {
        color: #9a9a9a;
      }
      &_R {
        color: #f14f44;
        margin-left: 17px;
        cursor: pointer;
      }
    }
  }
}

.conter {
  padding-bottom: 100px;
  width: 100%;
  .buzhou {
    width: 390px;
    margin: auto;
    margin-top: 50px;
    .logo-search-progress {
      margin: auto;
      width: 100%;
      height: 104px;
    }
  }
  .success {
    width: 900px;
    margin: 50px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .zhuce {
      font-size: 40px;
      margin-left: 20px;
      font-weight: 600;
      color: #ff902a;
    }
  }
  .denglu {
    width: 900px;
    margin: 50px auto;
    font-size: 20px;
    color: #245af2;
    cursor: pointer;
    text-align-last: center;
  }
}
</style>
